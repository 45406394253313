import React from "react";
import styles from "./AboutCoach.module.css";
import useOnScreen from "../../../hooks/useOnScreen";

function AboutCoach() {
  const [ref, isVisible] = useOnScreen({ threshold: 0.03 });

  let image =
    "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/trent2.png";
  let name = "TRENT CARR";
  let bodyText =
    "Trent Carr, the founder of Aura Baseball Academy (ABA), empowers aspiring baseball players to achieve their dreams. A former Collegiate pitcher, he brings grit and resilience, having won conference and Region championships, with a World Series appearance in the NJCAA. Trent has successfully coached over 200 players as a Pitching & Strength Coach and insured success throughout their playing career. As the UNC-Charlotte Club Baseball team's Pitching Coordinator, he develops personalized throwing programs and offers comprehensive pitching advice. With experience as an Assistant Clubhouse Manager for the AAA a liate of the Chicago White Sox, Trent understands the game's demands and players' needs. Currently pursuing a Marketing degree at the University of North Carolina at Charlotte, he utilizes his expertise to help athletes excel at Aura Baseball Academy.";
  return (
    <div ref={ref} className={styles.aboutCoach}>
      <div className={`${styles.title} ${isVisible && styles.popIn}`}>
        Meet the Coach
        <hr />
      </div>
      <div className={styles.header}>
        <img
          src={image}
          alt="Coach"
          className={`${styles.coachImage} ${isVisible && styles.fadeIn}`}
        />
        <span
          className={`${styles.coachName} ${isVisible && styles.slideInLeft}`}
        >
          {name}
        </span>
      </div>
      <p className={`${styles.bodyText} ${isVisible && styles.slideInRight}`}>
        {bodyText}
      </p>
    </div>
  );
}

export default AboutCoach;
