import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ServicesList.module.css";
import useOnScreen from "../../hooks/useOnScreen";

const ServicesList = () => {
  const navigate = useNavigate();
  const [ref, isVisible] = useOnScreen({ threshold: 0.01 });
  const servicesData = [
    {
      title: "Personalized Pitching Lessons",
      imageUrl:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/services-images/windup.jpeg",
    },
    {
      title: "Personalized Pitching Programs",

      imageUrl:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/services-images/pitch.jpeg",
    },
  ];

  return (
    <div ref={ref} className={styles.container}>
      <div className={`${styles.header} ${isVisible && styles.slideLeft}`}>
        Our Services
      </div>
      <hr />
      <div
        className={`${styles.servicesGrid} ${isVisible && styles.slideRight}`}
      >
        {servicesData.map((service, index) => (
          <div key={index} className={styles.serviceCard}>
            <img
              className={styles.serviceImage}
              src={service.imageUrl}
              alt={service.title}
            />
            <div className={styles.serviceTitle}>{service.title}</div>
          </div>
        ))}
      </div>
      <button
        className={`${styles.learnMoreButton} ${isVisible && styles.slideUp}`}
        onClick={() => {
          window.open("https://calendly.com/trent-aurabaseball/lessons");
        }}
      >
        Schedule Today!
      </button>
    </div>
  );
};

export default ServicesList;
