import React from "react";
import Slider from "react-slick";
import styles from "./TestimonialCarousel.module.css";
import useOnScreen from "../../hooks/useOnScreen";

const TestimonialCarousel = () => {
  const [ref, isVisible] = useOnScreen({ threshold: 0.1 });
  let testimonials = [
    {
      image: "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/taj.png",
      message:
        "Coach Trent Carr is an all-around great guy. He is always there to encourage, and tell you what you need to hear; he is honest and genuine in his approach. After 3-4 weeks with Coach Carr's guidance and attention to detail, I quickly improved significantly. Working with Coach Carr has greatly improved my consistency, accuracy, and confidence in my pitching. Whoever works with him is getting a great coach and a man with great character.",
      author: "Taj Jones",
      location: "- UNC-Charlotte",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/parker.png",
      message:
        "I've worked with Coach Carr since I was in 6th grade. Coach has helped me over the years with my pitching and other aspects of the game. He also helped me with the recruiting process for college. His experience and knowledge of the game have been a huge help for me to continue to play in college.",
      author: "Parker Shipman",
      location: "- Brunswick Community College",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/will.png",
      message:
        "I have known Trent Carr for over 5 years and he has coached my son William both on a travel team and individual instruction. Trent is an outstanding man and has helped my son with good fundamentals in all areas of baseball. Most recently he has helped my son with pitching fundamentals that helped him make his middle school team. Based on Trent's character and skills I believe any parent would have a great experience with Trent and their child would become a better player.",
      author: "Jim & Natalie Copeland",
      location: "- Parents of Will Copeland",
    },
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "linear",
    className: styles.slide,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.carouselContainer} ref={ref}>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className={styles.testimonialCard}>
            <div className={styles.text}>
              <img
                src={testimonial.image}
                alt={testimonial.author}
                className={`${styles.authorImage} ${
                  isVisible && styles.puffIn1
                }`}
              />
              <p className={`${styles.message} ${isVisible && styles.puffIn2}`}>
                "{testimonial.message}"
              </p>
              <div
                className={`${styles.author} ${isVisible && styles.puffIn3}`}
              >
                <span>{testimonial.author}</span>
                <span className={styles.location}>{testimonial.location}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
