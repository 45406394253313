import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import { useNavigate, useLocation } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // React Router's useLocation hook to listen for location changes
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleClick = () => {
    closeMenu();
  };

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isOpen && <div className={styles.overlay} onClick={closeMenu} />}
      <nav className={styles.navbar}>
        <div className={styles.brandLogo}>
          <img src="https://aurapa-site-resources.s3.us-east-2.amazonaws.com/logo_transparent_background.png" />
        </div>
        <div className={`${styles.navlinks} ${isOpen ? styles.open : ""}`}>
          <a
            onClick={() => {
              navigate("/home");
              closeMenu();
            }}
            className={styles.navLink}
          >
            Home
          </a>
          <a
            onClick={() => {
              navigate("/about");
              closeMenu();
            }}
            className={styles.navLink}
          >
            About
          </a>
          <a
            onClick={() => {
              window.open("https://calendly.com/trent-aurabaseball/lessons");
            }}
            className={styles.navLink}
          >
            Schedule
          </a>
         <a
            onClick={() => {
              window.open("https://aurabaseballacademy.shop");
            }}
            className={styles.navLink}
          >
            Shop
          </a>
        </div>
        <div
          className={`${styles.hamburger} ${isOpen ? styles.open : ""}`}
          onClick={handleMenu}
        >
          <div />
          <div />
          <div />
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
