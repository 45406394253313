import React from "react";
import styles from "./ContactCard.module.css";
import useOnScreen from "../../hooks/useOnScreen";

const ContactCard = () => {
  const [ref, isVisible] = useOnScreen({ threshold: 0.2 });
  let contact = {
    image:
      "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/trent.jpeg",
    name: "Trent Carr",
    phone: "423-327-4853",
    email: "trent@aurabaseballacademy.com",
    temp: "",
  };

  return (
    <>
      <div ref={ref} className={styles.contactCard}>
        <div className={styles.contactInfo}>
          <div className={`${styles.title} ${isVisible && styles.slideDown}`}>
            Contact
          </div>
          <div className={styles.rightColumn}>
            <h2 className={`${styles.name} ${isVisible && styles.slideLeft}`}>
              {contact.name}
            </h2>
            <p className={`${styles.phone} ${isVisible && styles.slideLeft}`}>
              Charlotte Metro Area, NC
            </p>
            <div
              className={`${styles.buttonsContainer} ${
                isVisible && styles.slideRight
              }`}
            >
              <button
                className={styles.contactButton}
                onClick={() => (window.location.href = `tel:${contact.phone}`)}
              >
                Call
              </button>
              <button
                className={styles.contactButton}
                onClick={() =>
                  (window.location.href = `mailto:${contact.email}`)
                }
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
