import React from "react";
import styles from "./Header.module.css";
import useOnScreen from "../../../hooks/useOnScreen";

function Header() {
  const [ref, isVisible] = useOnScreen({ threshold: 0.03 });
  let image =
    "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/group.jpg";
  let title = "UNLOCK YOUR POTENTIAL.";
  let subtitle = "ELEVATE YOUR GAME.";
  let bodyText =
    "“In 2023, I founded Aura Baseball Academy (ABA) with a singular vision: to empower aspiring baseball players in realizing their dreams of reaching the next level. My primary focus is on shaping determined and hardworking individuals who approach life with grit and resilience. My unparalleled passion for baseball fuels my unwavering dedication, guaranteeing a commitment to excellence in every interaction with the sport. I am committed to utilizing all my abilities and resources to help your athlete improve and surpass their previous performance levels.”";
  return (
    <header ref={ref} className={styles.header}>
      <img
        src={image}
        alt="Header background"
        className={styles.backgroundImage}
      />
      <div className={styles.overlay}></div>
      <div className={styles.textWrapper}>
        <span className={`${styles.title} ${isVisible && styles.focusIn1}`}>
          {title}
        </span>
        <span className={`${styles.subtitle} ${isVisible && styles.focusIn2}`}>
          {subtitle}
        </span>
        <p className={`${styles.bodyText} ${isVisible && styles.puffIn}`}>
          {bodyText}
          <br /> <br />
          -Trent Carr
        </p>
      </div>
    </header>
  );
}

export default Header;
