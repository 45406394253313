import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import styles from "./Carousel.module.css";
import useOnScreen from "../../hooks/useOnScreen";

const Carousel = () => {
  const [step, setStep] = useState(0);
  const [ref, isVisible] = useOnScreen({ threshold: 0.03 });
  useEffect(() => {
    console.log(step);
  }, [step]);

  let data = [
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
      image2:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
      secondaryImg:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
      title: "Ambition",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
      image2:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
      title: "Unity",
      secondaryImg:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
      image2:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
      title: "Resilience",
      secondaryImg:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
      image2:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
      title: "Accountability",
      secondaryImg:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
    },
    {
      image:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
      image2:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
      title: "AURA",
      secondaryImg:
        "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
    },
  ];

  let links = {
    image:
      "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching1.jpeg",
    image2:
      "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/pitching2.jpeg",
    title: "AURA",
    secondaryImg:
      "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/white_logo_transparent_background.png",
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    afterChange: (next) => setStep(next),
    fade: true,
  };

  return (
    <div ref={ref} className={styles.carouselContainer}>
      <div className={styles.slide}>
        <img
          src={links.image}
          alt={links.title}
          className={`${styles.image} ${step === 4 && styles.fadeOut}`}
        />
        <img
          src={links.image2}
          alt={links.title}
          className={`${styles.image2} ${step === 4 && styles.fadeIn}`}
        />
        <div className={styles.text}>
          <div className={styles.contents}>
            <Slider {...settings}>
              {data.map((item, index) => (
                <div
                  className={`${styles.title} ${
                    step === 4
                      ? styles.aura
                      : index === step && styles.highlight
                  }`}
                >
                  {item.title}
                </div>
              ))}
            </Slider>
            <img
              src={links.secondaryImg}
              className={`${styles.secondaryImg} ${
                isVisible && styles.slideUp
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
