import React from "react";
import styles from "./About.module.css";
import Header from "./Header/Header";
import AboutCoach from "./AboutCoach/AboutCoach";
import Values from "./Values/Values";

const About = () => {
  return (
    <div className={styles.aboutContainer}>
      <Header />
      <AboutCoach />
      <Values />
    </div>
  );
};

export default About;
