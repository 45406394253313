import React, { useState, useRef } from "react";
import Slider, { slickGoTo } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Values.module.css";
import useOnScreen from "../../../hooks/useOnScreen";

const values = [
  {
    initial: "A",
    title: "Accountability",
    text: "We empower players to take ownership, learn from mistakes, and continuously improve. With personalized support, our players develop reliability, personal responsibility, and resilience on and off the field.",
  },
  {
    initial: "U",
    title: "Unity",
    text: "We build strong relationships and create a cohesive and supportive environment where everyone strives towards common goals.",
  },
  {
    initial: "R",
    title: "Resilience",
    text: "Baseball players at Aura Baseball Academy develop mental toughness and learn to persevere through tough situations.",
  },
  {
    initial: "A",
    title: "Ambition",
    text: "Baseball players at Aura Baseball Academy are encouraged to set ambitious goals and work tirelessly to achieve them.",
  },
];

const Values = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef(null);
  const [ref, isVisible] = useOnScreen();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    className: styles.slide,
    afterChange: setCurrentSlide,
    arrows: false,
    fade: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let image =
    "https://aurapa-site-resources.s3.us-east-2.amazonaws.com/services-images/seats.jpeg";

  return (
    <div className={styles.container} ref={ref}>
      <div
        src={image}
        alt="Header background"
        className={styles.backgroundImage}
      />

      <div className={`${styles.title} ${isVisible && styles.slideDown}`}>
        {values.map((value, index) => (
          <span
            key={value.initial}
            className={index === currentSlide ? styles.highlight : ""}
            onClick={() => slideRef.current.slickGoTo(index)}
          >
            {value.initial}
          </span>
        ))}
      </div>
      <Slider {...settings} ref={slideRef}>
        {values.map((value, idx) => (
          <div className={styles.slide}>
            <span
              className={`${styles.slideTitle} ${
                idx === currentSlide && styles.trackIn
              }`}
            >
              {value.title}
            </span>
            <span
              className={`${styles.slideText} ${
                idx === currentSlide && styles.puffIn
              }`}
              key={value.text}
            >
              {value.text}
            </span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Values;
