import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { Navbar, Footer } from "./layout";
import { Carousel } from "./pages/Home";
import { About } from "./pages/About";
import { ContactCard } from "./pages/Contact";
import ServicesList from "./pages/ServicesList/ServicesList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { TestimonialCarousel } from "./pages/Testimonials";

function App() {
  const carouselRef = useRef(null);
  const contactCardRef = useRef(null);

  // Scroll to element function
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    toast("Welcome to Aura Baseball Academy! Click here to schdule a lesson!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      toastId: "welcome-toast",
      onClick: () => {
        window.open("https://calendly.com/trent-aurabaseball/lessons");
      },
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/about" element={<About></About>} />
          <Route
            exact
            path="*"
            element={
              <>
                <Carousel></Carousel>
                <ServicesList />
                <ContactCard></ContactCard>
                <TestimonialCarousel />
              </>
            }
          />
        </Routes>
        <Footer />
        <ToastContainer theme="dark" className="toasty" />
      </div>
    </Router>
  );
}

export default App;
