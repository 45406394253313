import { useState, useEffect, useRef } from "react";

function useOnScreen(options) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback is fired
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      // Cleanup the observer when component unmounts
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]); // Re-run if ref or options change

  return [ref, isVisible];
}

export default useOnScreen;
