import React from "react";
import styles from "./Footer.module.css";

const Footer = () => (
  <div className={styles.footer}>
    <a
      href="https://www.facebook.com/profile.php?id=100094092213076&mibextid=LQQJ4d"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className={`fab fa-facebook-f ${styles.icon}`}></i>
    </a>
    <a
      href="https://www.instagram.com/aurabaseballacademy"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className={`fab fa-instagram ${styles.icon}`}></i>
    </a>
    <a
      href="https://twitter.com/AuraBaseball"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className={`fab fa-x-twitter ${styles.icon}`}></i>
    </a>
  </div>
);

export default Footer;
